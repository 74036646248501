import React, { useEffect, useRef, useState } from "react";
import HeaderForApp from "./HeaderForApp";
import HeaderForWeb from "./HeaderForWeb";

function HeaderMain({ footerData }) {
  function isWebView() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Example for Android WebView (check for "wv")
    if (/wv/.test(userAgent) || /Android.*WebView/.test(userAgent)) {
      return true;
    }

    // Example for iOS WebView (check for "iPhone OS" without "Safari")
    if (/iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent)) {
      return true;
    }

    return false; // Assume it's a browser if no WebView signature is detected
  }

  if (isWebView()) {
    console.log("Running inside a WebView");
  } else {
    console.log("Running inside a browser");
  }
  return <div>{isWebView() ? <HeaderForApp /> : <HeaderForWeb />}</div>;
}

export default HeaderMain;
