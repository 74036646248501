import React from "react";
import { motion } from "framer-motion";
import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { allapiAction } from "../../Redux/common/action";
import { useNavigate } from "react-router-dom";
import HeaderMain from "../Layout/HeaderMain";

function SuccessPayment() {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);
  const router = useNavigate();

  const get_all_cart_itme = useSelector((state) =>
    state?.allapi?.get_all_cart_itme ? state?.allapi?.get_all_cart_itme : []
  );
  const loading_checkOut = useSelector((state) =>
    state?.allapi?.loading_checkOut ? state?.allapi?.loading_checkOut : false
  );

  const get_user_profile = useSelector((state) =>
    state?.allapi?.get_user_profile ? state?.allapi?.get_user_profile : {}
  );
  console.log(get_all_cart_itme);
  console.log(get_user_profile);
  const totalSubtotalPrice = get_all_cart_itme.reduce((total, obj) => {
    return total + obj.subtotalprice;
  }, 0);
  const totalSubtotalPricegst = get_all_cart_itme.reduce((total, obj) => {
    return total + (obj.subtotalprice * obj?.gst) / 100;
  }, 0);

  console.log(totalSubtotalPricegst);
  // createOrder
  const dispatch = useDispatch();

  const [paymentmode, setpaymentmode] = useState("wallet");

  useEffect(() => {
    setTimeout(() => {
      orderSumit();
    }, 1000);
    return () => {};
  }, []);

  const orderSumit = (e) => {
    // createOrder

    if (paymentmode == "online") {
      //   displayRazorpay();

      toast.error("now online payment mode not available");
    } else {
      const orderAddress = localStorage.getItem("order_Address");

      console.log(orderAddress);
      const obj = JSON.parse(orderAddress);
      console.log(obj);

      dispatch(
        allapiAction.createOrder(
          {
            user_name: get_user_profile?.name,
            user_email: get_user_profile?.email,
            user_mobile: get_user_profile?.mobile_no,
            delivery_charges: "0",
            sub_total: totalSubtotalPrice,
            address_id: obj,
            payment_mode_name: "wallet",
            // products_ids: commentsData,
          },
          router
        )
      );
      return () => {};
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 1, ease: "easeInOut" }}
      style={{ display: show ? "block" : "none" }}
    >
      <HeaderMain />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "calc(100vh - 0px)",
          padding: "20px",
          backgroundColor: "green",
          color: "#ffffff",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h1 style={{ color: "#ffffff" }}>Payment Successful</h1>
        <p style={{ color: "#ffffff" }}>
          Please check your email for order confirmation.
        </p>
      </div>
    </motion.div>
  );
}

export default SuccessPayment;
