import React from "react";
import { motion } from "framer-motion";
import HeaderMain from "../Layout/HeaderMain";
import { Link } from "react-router-dom";
// import { FaExclamationTriangle } from 'react-icons/fa';

function FaildPayment() {
  return (
    <div>
      <HeaderMain />
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
          backgroundColor: "#f8d7da",
          color: "#721c24",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* <FaExclamationTriangle size={50} style={{ marginBottom: '20px' }} /> */}
        <h1>Payment Failed</h1>
        <p>Please try again or contact support.</p>
        <Link to="/" className="btn btn-primary">Go to Home</Link>
      </motion.div>
    </div>
  );
}

export default FaildPayment;
