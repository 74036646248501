import React from 'react';
// import { motion } from 'framer-motion';
import { motion } from "motion/react"

function ProcessPayment() {
  return (
    <motion.div 
      style={{
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        height: '100vh', 
        flexDirection: 'column', 
        backgroundColor: '#eee', 
        padding: '20px'
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <motion.h1 
        style={{ color: '#007bff' }}
        animate={{ y: [0, -10, 0] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      >
        Please Wait
      </motion.h1>
      <motion.p 
        style={{ color: '#333' }}
        animate={{ scale: [1, 1.1, 1] }}
        transition={{ duration: 1.5, repeat: Infinity }}
      >
        Your payment is being processed. Please do not close this page.
      </motion.p>
    </motion.div>
  );
}

export default ProcessPayment;
